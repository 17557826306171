<template>
  <FocusTrap>
    <div class="card" id="myform">

    <div class="card-header header-elements-inline" style="background-color: lightgrey">
      <h5 class="card-title">Medicine Information</h5>
      <div class="header-elements">
        <div class="list-icons">
          <a class="list-icons-item" data-action="reload"></a>
          <a class="list-icons-item" data-action="remove" @click="closeWindow"></a>
        </div>
      </div>
    </div>

    <div class="card-body">
      <p/>


      <div class="form-group row">
        <label class="col-md-2 col-form-label font-weight-semibold">Name:</label>
        <div class="col-md-10">
          <input  id="txtcode" type="text" class="form-control" placeholder="Enter Name here..." maxlength="100" v-if="item" v-model="item.name" autocomplete="off" autofocus>
        </div>
      </div>

      <div class="form-group row">
        <label class="col-md-2 col-form-label font-weight-semibold">Group:</label>
        <div class="col-md-4">
          <select class="form-control" v-if="item" v-model="item.group_id">
            <option value="1">Primary</option>
            <option v-for="group in groups" v-bind:value="group.id">
              {{ group.name }}
            </option>
          </select>
        </div>

        <label class="col-md-2 col-form-label font-weight-semibold">Brand:</label>
        <div class="col-md-4">
          <select class="form-control" v-if="item" v-model="item.brand_id">
            <option value="1">Primary</option>
            <option v-for="brand in brands" v-bind:value="brand.id">
              {{ brand.name }}
            </option>
          </select>
        </div>
      </div>

      <div class="form-group row">
        <label class="col-md-2 col-form-label font-weight-semibold">Formulation:</label>
        <div class="col-md-10">
          <input  type="text" class="form-control" placeholder="Enter Formulation here..." maxlength="100" v-if="item" v-model="item.formulation" autocomplete="off" >
        </div>
      </div>

      <div class="form-group row">
        <label class="col-md-2 col-form-label font-weight-semibold">Classification:</label>
        <div class="col-md-10">
          <input  type="text" class="form-control" placeholder="Enter Classification here..." maxlength="100" v-if="item" v-model="item.classification" autocomplete="off" >
        </div>
      </div>

      <div class="form-group row">
        <label class="col-md-2 col-form-label font-weight-semibold">Packing:</label>
        <div class="col-md-4">
          <select class="form-control" v-if="item" v-model="item.packing">
            <option value="1">Primary</option>
            <option v-for="group in groups" v-bind:value="group.id">
              {{ group.name }}
            </option>
          </select>
        </div>

        <label class="col-md-2 col-form-label font-weight-semibold">Speciality:</label>
        <div class="col-md-4">
          <select class="form-control" v-if="item" v-model="item.speciality">
            <option value="1">Primary</option>
            <option v-for="brand in brands" v-bind:value="brand.id">
              {{ brand.name }}
            </option>
          </select>
        </div>

      </div>

<!--      Price Data-->
      <div class="form-group row">
        <label class="col-md-2 col-form-label font-weight-semibold">MRP:</label>
        <div class="col-md-2">
          <input type="number" min="0" class="form-control" v-if="item" v-model="item.mrp"/>
        </div>

        <label class="col-md-2 col-form-label font-weight-semibold">PTR:</label>
        <div class="col-md-2">
          <input type="number" min="0" class="form-control" v-if="item" v-model="item.ptr"/>
        </div>

        <label class="col-md-2 col-form-label font-weight-semibold">PTS:</label>
        <div class="col-md-2">
          <input type="number" min="0" class="form-control" v-if="item" v-model="item.pts"/>
        </div>
      </div>

      <div class="form-group row">
        <label class="col-md-2 col-form-label font-weight-semibold">Net Rate:</label>
        <div class="col-md-2">
          <input type="number" min="0" class="form-control" v-if="item" v-model="item.net_rate"/>
        </div>

        <label class="col-md-2 col-form-label font-weight-semibold">NRV:</label>
        <div class="col-md-2">
          <input type="number" min="0" class="form-control" v-if="item" v-model="item.nrv"/>
        </div>

        <label class="col-md-2 col-form-label font-weight-semibold">BO:</label>
        <div class="col-md-2">
          <input type="number" min="0" class="form-control" v-if="item" v-model="item.bo"/>
        </div>
      </div>


    </div>

    <div class="card-footer">
      <div class="text-right">
        <button type="button" class="btn btn-primary" @click="saveDocument">Save<i class="icon-paperplane ml-2"></i>
        </button>
      </div>
    </div>

  </div>
  </FocusTrap>
</template>

<script>
  import moment from 'moment-timezone'
  import { userService } from '@/store/auth-header.js'

  export default {
    name: 'MedicineForm',
    component: {},
    data () {
      return {
        item:  JSON.parse('{"id":0,"status":"Active","name":"","group_id":1,"brand_id":1,"formulation":"","packing":"","speciality":"","clasification":"","mrp":0,"ptr":0,"pts":0,"net_rate":0,"nrv":0,"bo":0,"group":{"id":1,"name":"","type":0,"status":"Active","hsn":"","nature":0,"unit_id":1,"gstrate":0,"itcrate":0,"unit":{"id":1,"type":0,"name":null,"status":"Active","digits":0}},"categories":[],"brand":{"id":1,"type":1,"name":"Primary","status":"Active"}}'),
        brands: [],
        groups: [],
      }
    },
    props: {
      myitem: {
        type: Object,
        default: () => JSON.parse('{"id":0,"status":"Active","name":"","group_id":1,"brand_id":1,"formulation":"","packing":"","speciality":"","clasification":"","mrp":0,"ptr":0,"pts":0,"net_rate":0,"nrv":0,"bo":0,"group":{"id":1,"name":"","type":0,"status":"Active","hsn":"","nature":0,"unit_id":1,"gstrate":0,"itcrate":0,"unit":{"id":1,"type":0,"name":null,"status":"Active","digits":0}},"categories":[],"brand":{"id":1,"type":1,"name":"Primary","status":"Active"}}')
      }
    },
    beforeMount () {
      this.item =  JSON.parse(JSON.stringify(this.myitem));
    },
    created () {

    },
    mounted () {
      const self = this;

      $('input[type=checkbox]').uniform();
      this.loadAreas();
      this.loadBrands();
      $('#txtcode').focus();
    },
    methods: {
      closeWindow () {
        this.$emit('item_window_closed');
      },
      loadBrands () {
        const self = this;

        const requestOptions = {
          method: 'GET',
          mode:"cors",
          headers: userService.authHeader()
        };


        $(self.$data.mytable).block({
          message: '<i class="icon-spinner2 spinner"></i>',
          overlayCSS: {
            backgroundColor: '#fff',
            opacity: 0.8,
            cursor: 'wait',
            'box-shadow': '0 0 0 1px #ddd'
          },
          css: {
            border: 0,
            padding: 0,
            backgroundColor: 'none'
          }
        });

        fetch(`${process.env.VUE_APP_ROOT_API}api/brands`,requestOptions).then(userService.handleResponse).then(function (resp) {
          if(resp.ok) {
            if( _.isArray(resp.data) ){

              self.$data.brands = resp.data;
            }
          } else {
            swal ( { title: "Oops!" ,  text: resp.msg, type:  "error"} );
          }
        }).catch(function (err) {
          swal ( { title: "Oh noes!" ,  text: err.toString(), type:  "error"} );
        }).finally(function () {
          $(self.$data.mytable).unblock();
        })

      },
      loadAreas () {
        const self = this;

        const requestOptions = {
          method: 'GET',
          mode: 'cors',
          headers: userService.authHeader()
        }

        self.$data.brands = []
        fetch(`${process.env.VUE_APP_ROOT_API}api/areas`, requestOptions).then(userService.handleResponse).then(function (resp) {
          if (resp.ok) {
            if (_.isArray(resp.data)) {
              self.$data.brands = resp.data
            }
          } else {
            swal({ title: 'Oops!', text: resp.msg, type: 'error' })
          }
        }).catch(function (err) {
          swal({ title: 'Oh noes!', text: err.toString(), type: 'error' })
        })

      },
      saveDocument () {
        const self = this;
        // self.$data.item.parent_id = parseInt(self.$data.item.parent_id)

        if (self.$data.item.name.trim().length < 1) {
          alert('Invalid Name')
          return
        } else if (parseInt(self.$data.item.group_id) < 1) {
          alert('Invalid Group')
          return
        } else if (self.$data.item.formulation.trim().length < 1) {
          alert('Invalid Formulation')
          return
        } else if (self.$data.item.clasification.trim().length < 1) {
          alert('Invalid Classification')
          return
        } else if (parseInt(self.$data.item.unit_id) < 1) {
          alert('Invalid Packing')
          return
        } else if (self.$data.item.speciality.trim().length < 1) {
          alert('Invalid Speciality')
          return
        } else if ( parseFloat(self.$data.item.mrp) <= 0) {
          alert('Invalid MRP')
          return
        } else if ( parseFloat(self.$data.item.ptr) <= 0) {
          alert('Invalid PTR')
          return
        } else if ( parseFloat(self.$data.item.pts) <= 0) {
          alert('Invalid PTS')
          return
        } else if ( parseFloat(self.$data.item.net_rate) <= 0) {
          alert('Invalid Net Rate')
          return
        } else if ( parseFloat(self.$data.item.nrv) <= 0) {
          alert('Invalid NRV')
          return
        } else if ( parseFloat(self.$data.item.bo) <= 0) {
          alert('Invalid BO')
          return
        }


        const requestOptions = {
          method: (self.$data.item.id == 0 ? 'POST' : 'PUT'),
          mode: 'cors',
          headers: userService.authHeader(),
          body: JSON.stringify(self.$data.item)
        }

        $('#myform').block({
          msg: '<i class="icon-spinner2 spinner"></i>',
          overlayCSS: {
            backgroundColor: '#fff',
            opacity: 0.8,
            cursor: 'wait',
            'box-shadow': '0 0 0 1px #ddd'
          },
          css: {
            border: 0,
            padding: 0,
            backgroundColor: 'none'
          }
        })

        fetch(`${process.env.VUE_APP_ROOT_API}api/item`, requestOptions).then(userService.handleResponse).then(function (resp) {
          if (resp.ok) {
            swal({ title: 'Success!', text: resp.msg, type: 'success', onClose: () => { $('#txtcode').focus()}, timer:1500 })
            self.item = JSON.parse('{"id":0,"status":"Active","name":"","group_id":1,"brand_id":1,"formulation":"","packing":"","speciality":"","clasification":"","mrp":0,"ptr":0,"pts":0,"net_rate":0,"nrv":0,"bo":0,"group":{"id":1,"name":"","type":0,"status":"Active","hsn":"","nature":0,"unit_id":1,"gstrate":0,"itcrate":0,"unit":{"id":1,"type":0,"name":null,"status":"Active","digits":0}},"categories":[],"brand":{"id":1,"type":1,"name":"Primary","status":"Active"}}')
            self.$emit('item_saved', resp.data);

          } else {
            swal({ title: 'Oops!', text: resp.msg, type: 'error', timer:3000 })
          }
        }).catch(function (err) {
          swal({ title: 'Oh noes!', text: err.toString(), type: 'error', timer:3000 })
        }).finally(function () {
          $('#myform').unblock()
        })
      }
    }
  }
</script>

<style scoped>
  input:focus {
    background: #feff00;

  }

  textarea:focus {
    background: #feff00;

  }

  select:focus {
    background: #feff00;
  }

  button:focus {
    background: #feff00;
    color: #0a0a0a!important;
  }
</style>
